import { observable, action, decorate } from 'mobx';
import agent from '../agent';
import oAgent from '../agent-odata';
import userStore from './userStore';
import commonStore from './commonStore';
import signalr from 'SignalR/Connection'
import UserLoginDataInvalidError from 'Errors/UserLoginDataInvalidError'

class AuthStore {
  inProgress = false;
  errors: string[] | undefined = undefined;
  isRefreshingToken = false
  
  check(email: string){
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.exists(email)
      .then(resp => {
        if (resp && resp.status === 200) return false;
        
        return null
      })
      .catch(action((err) => {
        if (err.response && err.response.status === 409) return true;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  login(email: string, password: string,remember = false) {
    this.inProgress = true;
    this.errors = undefined;
    return agent.Auth.login(email, password)
      .then(user => {
        if(user !== null){
          if(user.token !== null){
            if(userStore.loginUser(user)){
            remember ? commonStore.setRemember(email) : commonStore.setRemember('')
          }
          }else{
            this.errors = ['invalid_login_attempt']
          }
        }
      })
      .catch(action((err) => {
        if(err instanceof UserLoginDataInvalidError){
          this.errors = [ 'invalid_service_login_returned_data']
          console.log(err)
          return
        }
        if(err.response.status === 400){
          this.errors = ['invalid_login_attempt']
          return
        }
        if(!err.handled){
          this.errors = ['unknown_error']
        }
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  register(newUserData: {Email: string, Password: string}) {
    this.inProgress = true;
    this.errors = undefined;
    return oAgent.Auth.register(newUserData)
      .then(resp => {
        if(resp.status === 201) this.login(newUserData.Email, newUserData.Password, true)
      })
      .catch(action((err) => {
        this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      }))
      .finally(action(() => { this.inProgress = false; }));
  }

  logout() {
    userStore.forget();
    signalr.disconnect()
    return Promise.resolve();
  }

  setIsRefreshingToken(value: boolean){
    this.isRefreshingToken = value
  }
}

decorate(AuthStore,{
  inProgress: observable,
  isRefreshingToken: observable,
  errors: observable,
  login: action,
  register: action,
  logout: action,
  setIsRefreshingToken: action,
})

export default new AuthStore();
