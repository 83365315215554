import { CancelTokenSource } from 'axios';
import { decorate, observable, action } from 'mobx';

export interface IAxiosControlStore {
  tokens: CancelTokenSource[];
  addToken(token: CancelTokenSource): void;
  cancelTokens(): void;
  clearTokens(): void;
}

class AxiosControlStore implements IAxiosControlStore  {
  tokens: Array<CancelTokenSource> = [];

  addToken(token: CancelTokenSource){
    this.tokens = [...this.tokens, token]
  }

  cancelTokens(){
    this.tokens.forEach(token=>{
      //Verificar si aun es posible cancelar o si ya fue cumplida la promesa
      if(token.cancel){ 
        token.cancel();
      }
    })
    this.clearTokens()
  }

  clearTokens(){
    this.tokens = []
  }
}

decorate(AxiosControlStore, {
  tokens: observable,
  addToken: action,
  cancelTokens: action,
  clearTokens: action,
})

const axce = new AxiosControlStore();
export default axce
