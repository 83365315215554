import React, { useState } from 'react'
import './index.css'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import { APP } from 'Constants/Routes'
import { LOGO_URL } from 'Constants/Media'
import { DefaultNewUser } from 'Constants/DefaultValues'
import useTitle from 'hooks/useTitle'
import * as yup from 'yup';
import { useForm } from "react-hook-form";
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import LanguageSelector from './LanguageSelector'
import i18n from 'i18n'


const Login = ({ authStore, userStore, commonStore }) => {
    const { t } = useTranslation();


    useTitle(t('login'))

    const [step, setStep] = useState(1)
    const [userExists, setUserExists] = useState(false)

    const [remember, setRemember] = useState(commonStore.remember ? true : false)

    // Necesario para pasar el valor del correo del paso 1 al 2
    const [Email, setEmail] = useState(localStorage.getItem('rmb'))

    const validationSchema = yup.object().shape({
        Email: yup.string().email().required(),
        Password: (step === 2) ? yup.string().required().min(6) : yup.string(),
        PasswordRepeat: (step === 2 && !userExists) ?
            yup.string()
                .required()
                .min(6)
                .oneOf([yup.ref('Password'), null], 'validations:passwords_must_match') : yup.string(),
        Name: (step === 2 && !userExists) ?
            yup.string().required() : yup.string(),
        Phone: (step === 2 && !userExists) ?
            yup.string().required() : yup.string(),
    });

    const { register, handleSubmit, errors, } = useForm({ validationSchema: validationSchema });

    function nextStep(data) {
        if (step === 1) return step1Submit(data.Email)
        if (step === 2) return step2Submit(data)
    }

    function previous() {
        if (step === 2) {
            setUserExists(false)
            setStep(1)
        }
    }

    function step1Submit(email) {
        authStore.check(email).then(exists => {
            if (exists !== null) {
                setEmail(email)
                setUserExists(exists)
                setStep(2)
            }
        })
    }

    function step2Submit(data) {
        if (userExists) {
            authStore.login(data.Email, data.Password, remember)
        } else {
            const fullUserData = {
                ...DefaultNewUser,
                'Email': data.Email,
                'Password':data.Password,
                'LegalNameCompany': data.Name,
                'PeopleContact': data.Name,
                'UserName': data.Email,
                'Phone': data.Phone,
                'Languaje': i18n.language === 'es' ? 2 : 1,
            }
            authStore.register(fullUserData)
        }
    }

    function validatePassword() { // eslint-disable-line no-unused-vars
        /* if (password !== passwordRepeat) {
            setErrors([t('passwords_must_match')])
            return false;
        }
        if (password.length < 6) {
            setErrors([t('passwords_at_least_6')])
            return false;
        }
        if (password.length > 15) {
            setErrors([t('passwords_max_15')])
            return false;
        }
        if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[●!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]).{6,20}$/)) {
            setErrors([t('passwords_at_least_upp_low_dig_spe')])
            return false;
        }
        return true */
    }

    if(userStore.isUserLoggedInAndValid) return <Redirect to={{ pathname: APP }} />

    return (
        <div className="container-fluid h-100 ">
            <Row className="h-100">
                <Col md={7} className="bg-secondary d-none d-md-flex justify-content-center align-items-center bg-secondary h-100">
                    <img src="images/bg-01.jpg" alt="vsap-bg"></img>
                </Col>
                <Col md={5} className="h-100 overflow-auto">
                    <main role="main" className="p-4 ">
                        <div className="d-flex justify-content-center align-items-center mt-4">
                            <img src={LOGO_URL} alt="vsap-logo"></img>
                        </div>
                        <div >
                            <Form onSubmit={handleSubmit(nextStep)}>
                                <fieldset disabled={authStore.inProgress}>
                                    {step === 1 &&
                                        <StepOne
                                            register={register}
                                            errors={errors}
                                            Email={Email} />
                                    }
                                    {step === 2 &&
                                        <StepTwo
                                            register={register}
                                            errors={errors}
                                            Email={Email}
                                            userExists={userExists}
                                            previous={previous}
                                            setRemember={setRemember}
                                            remember={remember} />
                                    }
                                </fieldset>
                            </Form>
                        </div>
                        <LanguageSelector />
                    </main>
                </Col>
            </Row>
        </div>
    )
}

export default inject("authStore", "userStore", "commonStore")(observer(Login))