import { observable, action, decorate } from 'mobx';
import signalr from 'SignalR/Connection'
import userStore from 'stores/userStore'
import agent from 'agent'
import { IDriver, IDriverMapMarker, IFullDriver } from 'ServicesInterfaces';

class MapStore {
    isLoadigDriverForFilter: boolean = true
    driversForFilter: IDriver[] = []
    driversSelectedOnFilter: IDriver[] | null = null
    driversMarkers: IDriverMapMarker[] = []

    constructor(){
        this.reset()
    }

    reset(){
        this.isLoadigDriverForFilter = true
        this.driversForFilter = []
        this.driversSelectedOnFilter = null
        this.driversMarkers = []

        signalr.disconnect()
    }

    init(){
        this.reset()
        // Preparar conexión
        signalr.setUpSignalRConnection(userStore.currentCompany!.KeyMembership, userStore.currentCompany!.KeyCompany)

        // Definir las suscripciones
        signalr.hubProxy.on("PaintPin", (positionMap: IDriverMapMarker) =>
            this.onPositionSignal(positionMap)
        )

        

        this.getDriversFromService().then(()=>{
            //Iniciar la conexion de signal
            signalr.startConnection().then(() => {
                //acción de inicializacion
            })
        })
    }

    onPositionSignal(positionMap: IDriverMapMarker){
        let driverMarkerIndex = this.driversMarkers.findIndex(x=>x.Id === positionMap.Id)
        if(driverMarkerIndex > -1){
            this.driversMarkers[driverMarkerIndex] = {
                ...this.driversMarkers[driverMarkerIndex],
                Latitude: positionMap.Latitude,
                Longitude: positionMap.Longitude,
                Lunch: positionMap.Lunch
            }
        }else{
            this.driversMarkers = [...this.driversMarkers, positionMap]
        }
    }

    getDriversFromService(){
        return new Promise<void>((resolve)=>{
            this.isLoadigDriverForFilter = true
            agent.Drivers.all().then(driversData=>{
                let drivers: IDriver[] = driversData.map((driveData: IFullDriver)=>driveData.Driver)
                this.driversForFilter = drivers
                this.driversMarkers = drivers.map(driver => {
                    return {
                        Id: driver.Id,
                        Latitude: driver.Latitude,
                        Longitude: driver.Longitude,
                        Lunch: driver.Lunch,
                        Name: driver.CompleteName
                    } as IDriverMapMarker
                })
                resolve()
            }).finally(()=>this.isLoadigDriverForFilter = false)
        })
    }

    setDriversSelectedOnFilter(driversSelected: IDriver[] | null){
        this.driversSelectedOnFilter = driversSelected
    }
}

decorate(MapStore,{
    isLoadigDriverForFilter: observable,
    driversForFilter: observable,
    driversSelectedOnFilter: observable,
    driversMarkers: observable,
    reset: action,
    onPositionSignal: action,
    getDriversFromService: action,
    setDriversSelectedOnFilter: action
})

export default new MapStore();