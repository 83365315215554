import axiosControlStore from 'stores/axiosControlStore'
import authStore from "stores/authStore";
import commonStore from "stores/commonStore";
import userStore from "stores/userStore";
import uiStore from "stores/uiStore";
import driverDetailsModalStore from "stores/Components/driverDetailsModalStore"
import mapStore from './Pages/Map/mapStore';

class RootStore {
    axiosControlStore: typeof axiosControlStore;
    authStore: typeof authStore;
    commonStore: typeof commonStore;
    userStore: typeof userStore;
    uiStore: typeof uiStore;
    driverDetailsModalStore: typeof driverDetailsModalStore;
    mapStore: typeof mapStore;

    constructor(){
        this.axiosControlStore = axiosControlStore;
        this.authStore = authStore
        this.commonStore = commonStore
        this.userStore = userStore
        this.uiStore = uiStore
        this.driverDetailsModalStore = driverDetailsModalStore
        this.mapStore = mapStore
    }
}

export default new RootStore()