import React from 'react'
import './index.css'
import { Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
import { inject, observer } from 'mobx-react';
import LoadingSpinner from 'Components/LoadingSpinner'
import ErrorMessage from 'Components/ErrorMessage'

const StepOne = ({ register, errors, authStore,Email }) => {
    const { t } = useTranslation();

    return (<>
        <Form.Group className="wrap-input100 validate-input"
            controlId="login_email">
            <Form.Control className="input100"
                autocomplete="on"
                name="Email"
                placeholder={t('email')} 
                ref={register}
                defaultValue={Email}/>
            <span className="focus-input100"></span>
        </Form.Group>
        <ErrorMessage error={errors?.Email} />
        {authStore.inProgress 
            ? 
            <div className="d-flex justify-content-center">
                <LoadingSpinner />
            </div> 
            :
            <Button className="w-100 p-3 vs-rounded-1" type="submit"><b>{t('next')}</b></Button>
        }
        <div className="d-flex justify-content-center mt-2">
            <Link to='/Account/ForgotPassword'>{t('forgot_your_password')}</Link>
        </div>
    </>
    )
}

export default inject("authStore", "userStore", "commonStore")(observer(StepOne))