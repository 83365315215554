import React from 'react'
import { useTranslation } from 'react-i18next';


const ErrorMessage = ({ error }) => {
    const { t } = useTranslation();

    return (
        <>
            {error && 
                <span className="text-danger">
                    { typeof error.message === 'string' && t(error.message)}
                    { error.message.hasOwnProperty('key') && error.message.hasOwnProperty('values') &&
                        t(error.message.key)+': '+error.message.values[Object.keys(error.message.values)[0]]
                    }
                </span>
            }
        </>
    )
}

export default ErrorMessage