import axios from 'axios'
import authStore from 'stores/authStore'
import userStore from 'stores/userStore';
import uiStore from 'stores/uiStore'
import { refreshToken } from 'agent'
// Ya no se usa o.js, se ha adaptado el código que habia para que trabaje
// con axios, sin embargo la documentación de o.js puede ayudar a resolver
// algunas dudas de cómo funcona este código
// Documentación para o.js = https://github.com/janhommes/o.js?files=1

// NOTA: la instancia de axios es la misma al importarlo
// del paquete base, por tanto los interceptores y la configuración
// de axios-retry definidos 'agent.js' no hace falta 
// definirlos acá otra vez

const BASE_URL = `${process.env.REACT_APP_API_URL}/odata/`

const handleErrors = err => {
    if (err.response && err.response.status) {
      if(err.response.status === 401){
        authStore.logout()
      }
      if(err.response.status >= 500 && err.response.status <= 599){
        uiStore.addNotification(uiStore.appNotifications.INTERNAL_SERVER_ERROR)
      }
    }
    if (err && err.message && err.message === 'Network Error'){
      uiStore.addNotification(uiStore.appNotifications.NETWORK_ERROR)
    }
    throw err
  }
  
 
export const checkStatus = (response,statusCode) =>{
    return (response && response.status && response.status === statusCode)
}

export const responseBody = (response, statusCode) =>{
    if(checkStatus(response,statusCode)){
        return response.data
    }
};

/**
 * @param {string} resource Recurso a consultar
 * @param {Object} query consulta (ver documentacion o.js)
 * @param {bool} secured debe restringirse la consulta por KeyMembership y KeyCompany 
 */
export const get = (resource, query = undefined, secured = false) => {
    if(secured){
        const defaultFilter = `KeyMembership eq '${userStore.currentUser.KeyMembership}' 
        and KeyCompany eq '${userStore.currentCompany.KeyCompany}'`
        if(query){
            if(query.hasOwnProperty('$filter')){
                query.$filter = query.$filter+` and (${defaultFilter})`
            }else{
                query.$filter = defaultFilter
            }
        }else{
            query = {
                $filter: defaultFilter
            }
        }
    }

    let fullQuery = ''
    // Se arma un array con las claves y valores del objecto 'query'
    // Se pordía usar la función 'Object.entries()' pero la compatibiliad
    // de entre nevegaores no esta clara en la documentación
    const arr = Object.keys(query).map(key=>{
        return key+'='+query[key]
    })
    // Se unen los items del array en un solo string separados por '&'
    fullQuery = arr.join('&') 
    // Se agrega el '?' solo si realmente hay una consulta
    if(fullQuery.length) fullQuery = '?'+fullQuery

    return axios.get(`${BASE_URL}`+resource+fullQuery)
        .catch(handleErrors)
    }

/**
 * 
 * @param {string} resource
 * @param {Object} data Datos a enviar
 * @param {bool} signedObject Indica si se deben agregar KeyMembership y KeyCompany 
 *  al objeto antes de enviarlo
 */
export async function post(resource, data, signedObject = true) {
    if(signedObject){
        data = {...data, 
            'KeyMembership': userStore.currentUser.KeyMembership,
            'KeyCompany': userStore.currentCompany.KeyCompany
        }
    }

    return axios.post(`${BASE_URL}${resource}`, data).catch(handleErrors)
}

/**
 * 
 * @param {string} resource
 * @param {integer} id ID del registro a modificar
 * @param {Objet} data Datos a enviar
 * @param {bool} signedObject Indica si se deben agregar KeyMembership y KeyCompany 
 * @param {bool} signedId Indicar si ademas del ID, se debe usar
 *  KeyMembership y KeyCompany tambien para apuntar al registro a modificar
 */
export async function patch(resource, id, data, signedObject = true, signedId = true) {
    if(signedObject){
        data = {...data, 
            'KeyMembership': userStore.currentUser.KeyMembership,
            'KeyCompany': userStore.currentCompany.KeyCompany
        }
    }

    if (signedId){
        id += `,KeyMembership='${userStore.currentUser.KeyMembership}',KeyCompany='${userStore.currentCompany.KeyCompany}'`
    }

    return axios.patch(`${BASE_URL}/${resource}(${id})`, data)
            .catch(handleErrors)
}

/**
 * 
 * @param {string} resource 
 * @param {integer} id ID del registro a eliminar
 * @param {bool} signedId Indicar si ademas del ID, se debe usar
 *  KeyMembership y KeyCompany tambien para apuntar al registro a modificar
 */
export async function del(resource, id, signedId = true) {
    id = `guid'${id}'`

    if (signedId){
        id += `,KeyMembership='${userStore.currentUser.KeyMembership}',KeyCompany='${userStore.currentCompany.KeyCompany}'`
    }

    return axios.delete(`${BASE_URL}/${resource}(${id})`)
            .catch(handleErrors)
}