import uiStore from 'stores/uiStore'
import $ from 'jquery'
window.jQuery = $;
require('signalr')

/**
 * Para hecer uso de esta clase se debe tener en cuenta:
 * 1) Primero se debe ejuctar el metodo setUpSignalRConnection
 * 2) Desde el archivo donde se esta llamando esta clase, se deben definir
 *    las suscripciones (hubProxy.on) antes de llamar al metodo startConnection()
 * 3) Despues de definir las suscripciones es cuando se debe llamar al método
 *    startConnection().
 * 4) Los "invoke" se deben usar una vez que startConnection() se complete
 *    exitosamente.
 * 
 * Debe respetarse el orden o las suscripciones no funcionarán
 */
class SignalR {
    connection = undefined
    hubProxy = undefined
    keyMembership = undefined
    keyCompany = undefined
    tryingToReconnect = false;

    setUpSignalRConnection(keyMembership, keyCompany) {
        this.keyMembership = keyMembership
        this.keyCompany = keyCompany
        this.connection = $.hubConnection(`${process.env.REACT_APP_API_URL}/signalr/hubs`);
        this.hubProxy = this.connection.createHubProxy('apiHub');


        this.connection.reconnecting(() => {
            uiStore.addNotification(uiStore.appNotifications.NETWORK_RECONNECTING)
            this.tryingToReconnect = true;
        });

        this.connection.reconnected(function () {
            uiStore.addNotification(uiStore.appNotifications.NETWORK_RECONNECTED)
            this.tryingToReconnect = false;
        });

        this.connection.disconnected(() => {
            if (this.tryingToReconnect) {
                setTimeout(() => {
                    uiStore.addNotification(uiStore.appNotifications.NETWORK_RECONNECTING)
                    this.startConnection().then(()=>{
                        this.tryingToReconnect = false
                        uiStore.addNotification(uiStore.appNotifications.NETWORK_RECONNECTED)
                    })
                    .catch(()=>{})
                }, 5000)
            }
        });
        this.connection.connectionSlow(() => {
            uiStore.addNotification(uiStore.appNotifications.NETWORK_SLOW)
        });
    };

    startConnection() {
        return new Promise((resolve, reject) => {
            this.connection.start({ jsonp: true })
                .done(() => {
                    if(!this.tryingToReconnect){
                        this.hubProxy.invoke("joinCompany", this.keyMembership, this.keyCompany).then(() => {
                            resolve()
                        })
                    }else{
                        resolve()
                    }
                })
                .fail(() => { 
                    if(!this.tryingToReconnect){
                        uiStore.addNotification(uiStore.appNotifications.NETWORK_ERROR)
                    }
                    reject()
                 });
        })
    }

    disconnect() {
        if(this.connection) this.connection.stop()
        this.reset()
    }

    reset() {
        this.connection = undefined
        this.hubProxy = undefined
        this.keyMembership = undefined
        this.keyCompany = undefined
        this.tryingToReconnect = false
    }
}

export default new SignalR()