import React, { Suspense, createContext } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import { Provider } from "mobx-react";
import authStore from "stores/authStore";
import commonStore from "stores/commonStore";
import userStore from "stores/userStore";
import uiStore from "stores/uiStore";
import axiosControlStore from "stores/axiosControlStore";
import { BrowserRouter as Router } from "react-router-dom";
import './yup-settings';
import StoresContext from 'Contexts/StoresContext'
import RootStore from 'stores/RootStore'
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  
} else {
  Sentry.init({
    dsn: "https://9b9cf0d6ba724fc58eb79e39ebcc6ed1@o547834.ingest.sentry.io/5670593",
    integrations: [new Integrations.BrowserTracing()],
  
  
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0,
  });
}



const stores = {
  authStore,
  commonStore,
  userStore,
  uiStore,
  axiosControlStore
};


ReactDOM.render(
  <StoresContext.Provider value={RootStore}>
    <Provider {...stores}>
      <React.StrictMode>
        <Suspense fallback={<div></div>}>
          <Router>
            <App />
          </Router >
        </Suspense>
      </React.StrictMode>
    </Provider>
  </StoresContext.Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
