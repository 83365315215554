import { decorate, observable, action, computed } from 'mobx';
import AppNotifications, { IAppNotification } from 'Constants/AppNotifications'


class UIStore {

  appNotifications = AppNotifications
  notifications: IAppNotification[] = []
  sidebarBreakPoint = 992
  isSidebarVisible = (window.innerWidth > this.sidebarBreakPoint)

  addNotification(notification: IAppNotification) {
    this.notifications.push(notification)
  }

  dismissNotification(index: number) {
    this.notifications = this.notifications.filter((notification, i) => i !== index)
  }


  handleWindowResize() {
    this.isSidebarVisible = (window.innerWidth > this.sidebarBreakPoint)
  }

  toggleSidebar() {
    this.isSidebarVisible ? this.closeSidebar() : this.openSidebar()
  }

  openSidebar(){
    this.isSidebarVisible = true
    if(this.isSmallScreen) this.lockBodyScroll()
  }

  closeSidebar(){
    this.isSidebarVisible = false
    this.unlockBodyScroll()
  }

  lockBodyScroll() {
    window.document.body.style.overflow = "hidden"
  }

  unlockBodyScroll(){
    window.document.body.style.overflow = "auto"
  }

  showUnexpectedDataNotification(){
    this.addNotification(this.appNotifications.UNEXPECTED_SERVICE_DATA_RESPONSE)
  }

  get isSmallScreen() {
    return window.innerWidth < this.sidebarBreakPoint
}
}

decorate(UIStore, {
  notifications: observable,
  addNotification: action,
  dismissNotification: action,
  isSidebarVisible: observable,
  toggleSidebar: action,
  openSidebar: action,
  closeSidebar: action,
  isSmallScreen: computed
})

export default new UIStore();
