import React from 'react'
import './index.css'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import { inject, observer } from 'mobx-react';
import LoadingSpinner from 'Components/LoadingSpinner'
import ErrorMessage from 'Components/ErrorMessage'


const StepTwo = ({Email, register, userExists, remember, setRemember, errors, authStore, previous }) => {
    const { t } = useTranslation();


    return (<>
        {/* Correo */}
        <Form.Group className="wrap-input100 validate-input"
            controlId="login_email">
            <Form.Control className="input100"
                autocomplete="on"
                name="Email"
                placeholder={t('email')} 
                ref={register}
                defaultValue={Email}
                disabled/>
            <span className="focus-input100"></span>
        </Form.Group>
        <ErrorMessage error={errors?.Email} />

        {/* Contraseña */}
        <Form.Group className="wrap-input100 validate-input mb-4">
            <Form.Control className="input100"
                type="password"
                placeholder={t('password')}
                ref={register}
                name="Password"
                />
            <span className="focus-input100"></span>
            {userExists === false && <small>{t('minimun')+' 6 '+t('characters')}</small>}
        </Form.Group>
        <ErrorMessage error={errors?.Password} />

        {/* Campo para repetir contraseña */}
        {userExists === false &&
            <div>
                <Form.Group controlId="PasswordRepeat" className="wrap-input100 validate-input">
                    <Form.Control className="input100"
                        type="password"
                        placeholder={t('repeat_password')} 
                        ref={register}
                        name="PasswordRepeat"/>
                    <span className="focus-input100"></span>
                </Form.Group>
                <ErrorMessage error={errors?.PasswordRepeat} />

                <Form.Group controlId="Name" className="wrap-input100 validate-input">
                    <Form.Control className="input100"
                        type="text"
                        placeholder={t('name')} 
                        ref={register}
                        name="Name" />
                    <span className="focus-input100"></span>
                </Form.Group>
                <ErrorMessage error={errors?.Name} />

                <Form.Group controlId="Phone" className="wrap-input100 validate-input">
                    <Form.Control className="input100"
                        type="text"
                        placeholder={t('phone')}
                        ref={register}
                        name="Phone"/>
                    <span className="focus-input100"></span>
                </Form.Group>
                <ErrorMessage error={errors?.Phone} />
            </div>
        }

        {/* Errores de validación */}
        {authStore.errors &&
            <div className="text-center">
                {authStore.errors.map(error => {
                    return <span className="text-danger">{t(error)}</span>
                })}
            </div>}
        

        {/* Checkbox "Recordarme" */}
        {userExists === true &&
            <Form.Group controlId="remember">
                <Form.Check
                    type="checkbox"
                    checked={remember}
                    label={t('remember_me')}
                    onChange={(e) => { setRemember(e.target.checked) }} />
            </Form.Group>
        }

        {/* Botones */}
        {authStore.inProgress ?
            <Row>
                <Col xs={12} sm={12} md={12} lg={12} className="d-flex justify-content-center">
                    <LoadingSpinner />
                </Col>
            </Row>
            :
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} className="order-1 order-sm-1 order-md-0 mb-1 mb-sm-1">
                    <Button variant="warning" className="w-100 p-3 vs-rounded-1"
                        onClick={previous}>
                        <b>{t('return')}</b>
                    </Button>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} className="order-0 order-sm-0 order-md-1 mb-1 mb-sm-1">
                    <Button className="w-100 p-3 vs-rounded-1" type="submit">
                        <b>{userExists ? t('login') : t('register')}</b>
                    </Button>
                </Col>
            </Row>
        }


    </>)
}

export default inject("authStore", "userStore", "commonStore")(observer(StepTwo))