import {get, post, patch, del, responseBody, checkStatus} from './AgentSetup'
const RESOURCE = 'Prescriptions'

const all = () => get(RESOURCE,{'$inlinecount': 'allpages'},false)
                    .then(response => responseBody(response,200))

const add = (data) => 
    post(RESOURCE,data)

const update = (id, data) => 
    patch(RESOURCE,`IdPrescription=${id},RowPrescription=${data.RowPrescription}`,data)
    .then(response=>checkStatus(response,204))

const delt = (id,RowPrescription) => 
    del(RESOURCE,`IdPrescription=${id},RowPrescription=${RowPrescription}`)
    .then(response=>checkStatus(response,200))

const single = id => 
    get(RESOURCE,{$filter: `IdPrescription eq ${id}`})
    .then(response=>responseBody(response,200))

const search = (term,from,to,top,skip, status) => 
    get(RESOURCE,{
        $filter: `(
            substringof('${term}',PrescriptionNumber) eq true or 
            substringof('${term}',DocumentId) eq true or 
            substringof('${term}',Name) eq true or 
            substringof('${term}',Phone) eq true or 
            substringof('${term}',Address) eq true or 
            substringof('${term}',Zone) eq true or 
            substringof('${term}',AddressDelivery) eq true)
            and (DateCreation ge datetime'${from}T00:00:00' and DateCreation le datetime'${to}T23:59:59')
            ${status > 0? ` and (Status eq ${status})`:``}`,
        '$inlinecount': 'allpages',
        $skip: skip, 
        $top: top,
        $orderby:'DateCreation'
    },undefined,false).then(response => responseBody(response,200))

const byStatus = status => get(RESOURCE,{$filter: `Status eq ${status}`},false)
    .then(response=>responseBody(response,200))

const relatedToPatient = (patientId,top) => 
    get(RESOURCE,{$filter: `IdPatient eq guid'${patientId}'`,$top: top})
    .then(response=>responseBody(response,200))

const getPrescriptionsToBeAudited = () =>
    get(RESOURCE,{$filter:'Status eq 7 and IdAuditHeader ne 0 and AuditedPharmacy eq false'})
    .then(response=>responseBody(response,200))

export default {
    all,
    add,
    update,
    delt,
    single,
    search,
    byStatus,
    relatedToPatient,
    getPrescriptionsToBeAudited
}