import React, { useEffect } from 'react'
import NotificationsTypes from 'Constants/NotificationsTypes'
import { useTranslation } from 'react-i18next';
import { IAppNotification } from 'Constants/AppNotifications';

type props = {
    notification: IAppNotification,
    index: number,
    handleDissmission(index: number): void
}

const Notification = ({notification, index, handleDissmission}: props) => {
    const { t } = useTranslation();

    useEffect(()=>{
        if(notification.isAutoDismissable) setTimeout(()=>{dismiss()}, 3000);
    },[]) // eslint-disable-line react-hooks/exhaustive-deps

    function selectStyle(type: string) {
        if (type === NotificationsTypes.ERROR) return 'danger'
        if (type === NotificationsTypes.SUCCESS) return 'success'
        if (type === NotificationsTypes.WARNING) return 'warning'
        return 'primary'
    }

    function dismiss(){
        handleDissmission(index)
    }

    return (
        <li className={'px-4 py-2 text-center bg-' + selectStyle(notification.type)}>
            {t(notification.message)}
            <div className="float-right close-button"
                onClick={() => dismiss()}>
                <i className="fas fa-times"/>
            </div>
        </li>
    )
}

export default Notification