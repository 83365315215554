/**
 * Notificaciones pre-escritas
 */
import NotificationsTypes from './NotificationsTypes'

export interface IAppNotification {
    type: string;
    message: string;
    isAutoDismissable: boolean;
}

class AppNotification implements IAppNotification{
    type: string
    message: string
    isAutoDismissable: boolean
    
    constructor(data: IAppNotification){
        this.type = data.type;
        this.message = data.message;
        this.isAutoDismissable = data.isAutoDismissable;
    }
}


const AppNotifications = {
    NETWORK_ERROR: {
        type: NotificationsTypes.ERROR,
        message: 'notifications:connection_error',
        isAutoDismissable: false
    },
    SUCCESS_CREATED: {
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:succesfull_created',
        isAutoDismissable: true
    },
    SUCCESS_EDITED: {
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:succesfull_edited',
        isAutoDismissable: true
    },
    SUCCESS_DELETED:{
        type: NotificationsTypes.WARNING,
        message: 'notifications:succesfull_deleted',
        isAutoDismissable: true
    },
    SUCCESS_PRESCRIPTION_ASSIGN:{
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:succesfull_prescription_assign',
        isAutoDismissable: true
    },
    SUCCESS_UNDO_PRESCRIPTION_ASSIGNATION:{
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:succesfull_undo_prescription_assignation',
        isAutoDismissable: true
    },
    INTERNAL_SERVER_ERROR:{
        type: NotificationsTypes.ERROR,
        message: 'notifications:server_error',
        isAutoDismissable: false
    },
    NETWORK_TROUBLES:{
        type: NotificationsTypes.WARNING,
        message: 'notifications:connection_problems',
        isAutoDismissable: true
    },
    NETWORK_DISCONNECTED: {
        type: NotificationsTypes.ERROR,
        message: 'notifications:connection_lost',
        isAutoDismissable: true
    },
    NETWORK_SLOW: {
        type: NotificationsTypes.WARNING,
        message: 'notifications:connection_slow',
        isAutoDismissable: true
    },
    NETWORK_RECONNECTING: {
        type: NotificationsTypes.WARNING,
        message: 'notifications:connection_reconnecting',
        isAutoDismissable: true
    },
    NETWORK_RECONNECTED: {
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:connection_reestablished',
        isAutoDismissable: true
    },
    PRESCRIPTION_FREED_SUCCESS:{
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:prescription_freed_successfull',
        isAutoDismissable: true
    },
    INVALID_ADDRESS:{
        type: NotificationsTypes.ERROR,
        message: 'notifications:must_selet_valid_address',
        isAutoDismissable: true
    },
    UNASSIGNED_PRESCRIPTIONS_LIST_WAS_UPDATED:{
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:list_of_unassigned_prescriptions_was_updated',
        isAutoDismissable: true
    },
    UNEXPECTED_SERVICE_DATA_RESPONSE:{
        type: NotificationsTypes.ERROR,
        message: 'notifications:the_server_response_was_unexpected',
        isAutoDismissable: false
    },
    SUCCESS_AUDIT:{
        type: NotificationsTypes.SUCCESS,
        message: 'notifications:the_audit_was_successful',
        isAutoDismissable: true
    },
}

export default AppNotifications