import React from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';
import Languages from 'Constants/Languages'
import i18n from 'i18n';



const LanguageSelector = () => {
    const { t } = useTranslation();

    function languegeChangeHandle(e) {
        i18n.changeLanguage(e.target.value);
    }
    
    return (
        <div>
            <Row>
                <Col xs={2} sm={2} md={4} lg={4}></Col>
                <Col xs={8} sm={8} md={4} lg={4} className="mt-5">
                    <Form.Group controlId="exampleForm.ControlSelect1">
                        <Form.Label>{t('language')}</Form.Label>
                        <Form.Control as="select" onChange={languegeChangeHandle}
                            defaultValue={localStorage.getItem('i18nextLng')}>
                            {/* {Languages.map(language => {
                                return <option key={language.iso639_1code} value={language.iso639_1code}>{language.name}</option>
                            })} */
                            }
                            { Object.keys(Languages).map(langKey=>{
                                return <option 
                                            key={Languages[langKey].iso639_1code} 
                                            value={Languages[langKey].iso639_1code}>
                                                {Languages[langKey].name}
                                        </option>
                            }) }
                        </Form.Control>
                    </Form.Group>
                </Col >
                <Col xs={2} sm={2} md={4} lg={4}></Col>
            </Row>
        </div>
    )
}

export default LanguageSelector