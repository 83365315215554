import { observable, action, decorate } from 'mobx';
import Agent from 'agent'
import uiStore from 'stores/uiStore'
import userStore from 'stores/userStore'
import signalr from 'SignalR/Connection'

class DriverDetailsModalStore {
    showModal = false
    driverDetails = undefined
    isLoading = true
    prescriptions = []

    reset(){
        this.showModal = false
        this.driverDetails = undefined
        this.isLoading = true
        this.prescriptions = []
    }

    closeModal() {
        this.showModal = false
    }

    openModal(driver) {
        this.isLoading = true
        this.driverDetails = driver
        this.showModal = true

        Agent.Drivers.single(this.driverDetails.Driver.Id).then(driverData => {
            this.prescriptions = driverData.Prescriptions

        }).finally(() => {
            this.isLoading = false
        }) 
    }

    freePrescriptionFromDriver(prescriptionToFree) {
        return new Promise((resolve, reject) => {
            const driverId = this.driverDetails.Driver.Id

            const prescriptionsToUnassign = [{
                IdPrescription: prescriptionToFree.IdPrescription,
                RowPrescription: prescriptionToFree.RowPrescription
            }]

            signalr.hubProxy.invoke(
                "unassignByBatch",
                userStore.currentCompany.KeyMembership,
                userStore.currentCompany.KeyCompany,
                prescriptionsToUnassign,
                driverId,
            )
                .then(() => {
                    this.prescriptions = this.prescriptions.filter(
                        x => x.IdPrescription !== prescriptionToFree.IdPrescription
                    )

                    uiStore.addNotification(uiStore.appNotifications.PRESCRIPTION_FREED_SUCCESS)

                    resolve()
                })
                .catch(err => {
                    throw err
                })
        })
    }
}

decorate(DriverDetailsModalStore, {
    showModal: observable,
    driverDetails: observable,
    isLoading: observable,
    prescriptions: observable,
    reset: action,
    openModal: action,
    closeModal: action
})

export default new DriverDetailsModalStore();