import userStore from './stores/userStore';
import authStore from './stores/authStore';
import uiStore from './stores/uiStore'
import axios from 'axios'
import axiosControlStore from './stores/axiosControlStore'
import LanguageParser from 'Utilities/LanguageParser'
import * as rax from 'retry-axios';


// NOTA: las definiciones a continuacion de axios-retry y el interceptos
// de axios afectan tambien a axios en el archivo 'agent-odata/AgentSetup.js'

const API_ROOT = `${process.env.REACT_APP_API_URL}/api`;

rax.attach();

axios.defaults.raxConfig = {
  instance: axios,
  retry: 5,
  noResponseRetries: 5,
  retryDelay: 3000,
  backoffType: 'static',
  onRetryAttempt: err => {
    const cfg = rax.getConfig(err);
    if (cfg.currentRetryAttempt === 3) {
      uiStore.addNotification(uiStore.appNotifications.NETWORK_TROUBLES)
    }
  },
};

axios.interceptors.request.use(function (config) {
  let source = axios.CancelToken.source();

  config.cancelToken = source.token;
  config.requestSourceLocation = window.location;
  // agregar el token de axios al store para que este disponible en cualquier parte y poder cancelarlo
  axiosControlStore.addToken(source);

  if (userStore.isUserLoggedInAndValid) {
    // La petición de refresh debe ir sin el token vencido en la cabecera
    if (!authStore.isRefreshingToken) {
      const token = userStore.currentUser.token;
      config.headers.authorization = "Bearer " + token;
    }
    config.headers.keymembership = userStore.currentUser.KeyMembership;
    config.headers.keycompany = userStore.currentCompany.KeyCompany
  }
  return config;
});

// Cola de promesas no cumplidas por error 401 (expiró el token)
let failedQueue = [];

// Aqui se realizar el refrescamiento del token
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {

  const originalRequest = error.config;

  if (error.response.status === 401 && !originalRequest._retry) {

    // Si se ejecutan 2 o más peticiones consecutivas
    // la primera disparará la peticion de refresh y las siguientes serán enviadas a la cola
    // y resueltas cuando el proceso de refresh se complete con exito
    if (authStore.isRefreshingToken) {
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject })
      }).then(token => {
        originalRequest.headers.authorization = 'Bearer ' + token;
        return axios(originalRequest);
      }).catch(err => {
        return Promise.reject(err);
      })
    }

    originalRequest._retry = true;
    authStore.setIsRefreshingToken(true)

    return new Promise(function (resolve, reject) {
      axios.get(`${API_ROOT}/Account/Refresh?refreshtoken=${userStore.currentUser.refresh_token}`)
        .then(({ data }) => {
          userStore.updateTokens(data.token, data.refreshtoken)
          originalRequest.headers.authorization = 'Bearer ' + data.token;
          // Con el token nuevo, resolver las otras promeesas que puedan estar pendientes en la cola.
          processQueue(null, data.token);
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          authStore.logout()
          reject(err);
        })
        .finally(() => { authStore.setIsRefreshingToken(false) })
    })
  }

  return Promise.reject(error);
});

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  })

  failedQueue = [];
}

const handleErrors = err => {
  if (err.response && err.response.status) {
    if (err.response.status === 401) {
      authStore.logout()
      err.handled = true
    }
    if (err.response.status >= 500 && err.response.status <= 599) {
      uiStore.addNotification(uiStore.appNotifications.INTERNAL_SERVER_ERROR)
      err.handled = true
    }
  }
  if (err && err.message && err.message === 'Network Error') {
    uiStore.addNotification(uiStore.appNotifications.NETWORK_ERROR)
    err.handled = true
  }
  throw err
}

const responseBody = res => {
  if (res && res.data) return res.data
  return res
};

const requests = {
  del: url =>
    axios
      .delete(`${API_ROOT}${url}`)
      .catch(handleErrors),

  get: (url, options = null) =>
    axios
      .get(`${API_ROOT}${url}`, options)
      .catch(handleErrors),
  put: (url, data) =>
    axios
      .put(`${API_ROOT}${url}`, data)
      .catch(handleErrors),

  post: (url, data) =>
    axios
      .post(`${API_ROOT}${url}`, data)
      .catch(handleErrors),

};

const Auth = {
  current: () =>
    requests.get('/Account/UserInfo').then(responseBody),
  login: (email, password) =>
    requests.post('/Account/Login', { Email: email, Password: password }).then(responseBody),
  exists: email =>
    requests.get(`/Account/ExistUser?UserName=${email}`),
  refresh: () =>
    requests.get(`/Account/Refresh?refreshtoken=${userStore.currentUser.refresh_token}`)
};

const Drivers = {
  all: () =>
    requests.get(`/Users/GetDrivers/`).then(responseBody),
  single: (id) =>
    requests.get(`/Users/GetDriver/${id}`).then(responseBody)
}

const Prescriptions = {
  assing: (idPrescription, idDriverAssing) =>
    requests.get(`/Prescriptions/AssignOrderToDriver/${idPrescription}/${idDriverAssing}`)
      .then(responseBody),
  updatePrescriptionsAudited: (prescriptions) =>
    requests.post("/Prescriptions/UpdatePrescriptionsAudited", prescriptions).then(responseBody)
}

const ApiPrescriptionComplete = {
  getPrescriptionOpenCompletesForAudit: () =>
    requests.get(`/ApiPrescriptionCompletes/GetPrescriptionOpenCompletesForAudit?language=${LanguageParser.parseCurrentLanguege()}`)
      .then(responseBody),
  getPrescriptionCompletesAudited: (from, to, idDriver) =>
    requests.get(`/ApiPrescriptionCompletes/GetPrescriptionCompletesAudited?language=${LanguageParser.parseCurrentLanguege()}&from=${from}&to=${to}&IdDrivers=${idDriver}`)
      .then(responseBody)
}

const CloseTurn = {
  getCloseHeaders: (from, to, IdDriver = null, CloseNumber = null) =>
    requests.get(`/CloseTurn/GetCloseHeaders`, { params: { from, to, IdDriver, CloseNumber } })
      .then(responseBody),
  getCloseDetails: (CloseNumber, IdDriver) =>
    requests.get(`/CloseTurn/GetCloseDetails`, { params: { CloseNumber, IdDriver } })
      .then(responseBody),
}



export default {
  Auth,
  Drivers,
  Prescriptions,
  ApiPrescriptionComplete,
  CloseTurn,
};