// Necesario para que el servicio de OData pueda parsear la información
// que se le envia.
// Las lineas comentadas representan propiedades que deben ser llenadas en los formularios
// o en el agente antes de enviar a OData 
import { IPrescription } from 'ServicesInterfaces'

export const PrescriptionDefault: IPrescription = {
      IdPrescription: "1",
      //IdPatient: "1",
      RowPrescription: 0,
      TimePrescriptionAssing: "2000-01-01T00:00:00",
      Closed: false,
      DateClosed: "2000-01-01T00:00:00",
      CloseNumber: "0",
      IdAuditHeader: "0",
      AuditedPharmacy: false,
      DateAudited: null,
      StatusAudited: null,
      CommentAudited: null,
      New: false,
      TimeNew: "2000-01-01T00:00:00",
      Pendient: false,
      TimePendient: "2000-01-01T00:00:00",
      InProcess: true,
      TimeImProcess: "2000-01-01T00:00:00",
      GivingOut: false,
      TimeGivingOut: "2000-01-01T00:00:00",
      Delivered: false,
      TimeDelivered: "2000-01-01T00:00:00",
      Undelivered: false,
      TimeUndelivered: "2000-01-01T00:00:00",
      ReturnedPharmacy: false,
      TimeReturnedPharmacy: "2000-01-01T00:00:00",
      OrderMaps: 1,
      InRoute: true,
      InRouteTimeCalculate: "2000-01-01T00:00:00",
      KeyUserCalculateRoute: "",
      DistanceRoute: 3771,
      TimeRoute: 453,
      Source: 3,
      DateCreation: "2000-01-01T00:00:00",
      Status: 1,
      DateStatus: "2000-01-01T00:00:00",
      IdDriverAssined: "",
      IdDriverAssing: "",
      PrescriptionNumber: '',
      Phone: '',
      Name: '',
      Address: '',
      AddressDelivery: '',
      Comment: '',
      IdPatient: '',
      Insurance: '',
      Latitude: '',
      Longitude: '',
      Zone: '',
}

export const DefaultNewUser = {
      BusinessType: 6,
      Direction: ".",
      Key: 1,
      Latitude: 0.1,
      //LegalNameCompany: '',
      Longitude: 0.1,
      //PeopleContact: '',
      //Phone: '',
      Plate: "",
      UseType: 1,
      //Languaje: 1,
}

export const DefaultPatientData = {
      IdPatient: "12345678-aaaa-bbbb-cccc-ddddeeeeffff",
      //Name: "",
      //DocumentoId: "0",
      //Address: "",
      //Phone: "",
      //Zone: "",
      DateCreation: "2020-05-23T00:00:00",
      IdUserCreation: "1",
      Source: 1,
      //Latitude: 0,
      //Longitude: 0,
      //KeyMembership: "",
      //KeyCompany: ""
  }