/* const languagues = {
    en: { iso639_1code: "en", name: "English", value: 0 },
    es: { iso639_1code: "es", name: "Español", value: 1 },
} */

const languagues = {
    en: { iso639_1code: "en", name: "English", value: 0 },
    es: { iso639_1code: "es", name: "Español", value: 1 },
}

export default languagues