import { decorate, observable, action, reaction, computed } from 'mobx';
import agent from '../agent';
import UserLoginDataInvalidError from 'Errors/UserLoginDataInvalidError'
import { IUser, ICompany } from 'ServicesInterfaces'
import * as Sentry from "@sentry/react";

class UserStore {

  currentUser: IUser | undefined = undefined
  currentCompany: ICompany | undefined = undefined
  loadingUser: boolean = false;

  constructor() {

    reaction(
      () => this.currentUser,
      currentUser => {
        if (currentUser) {
          let userData = JSON.stringify(currentUser);
          localStorage.setItem('currentUser', userData);
          Sentry.setContext("user", {
            name: currentUser.Name,
            login: currentUser.EMail,
          });
          
        } else {
          localStorage.removeItem('currentUser');
        }
      }
    )

    reaction(
      () => this.currentCompany,
      currentCompany => {
        if (currentCompany) {
          let companyData = JSON.stringify(this.currentCompany);
          localStorage.setItem('currentCompany', companyData);
          Sentry.setContext("company", {
            company_name: currentCompany?.Name,
              keymembership: currentCompany?.KeyMembership,
              keycompany: currentCompany?.KeyCompany
          });
        } else {
          localStorage.removeItem('currentCompany');
        }
      }
    )

    this.loadFromLocalStorage()
  }

  // comprueba que los datos traidos del servicio sean validos
  loginUser(user: any) {
    if (!user || user === null) {
      throw new UserLoginDataInvalidError("Invalid user login data. Data is undefined or null")
    }
    if (!user.Companies || !user.Companies.length) {
      throw new UserLoginDataInvalidError("The service provided user has no companies")
    }
    this.setCurrentUser(user)
    this.setCurrentCompany(user.Companies[0])
    return true
  }

  loadFromLocalStorage() {
    const userSuccessLoaded = this.loadUser()
    if (userSuccessLoaded) this.loadCompany()
  }

  loadUser() {
    try {
      // Intentar cagar el usuario
      let userData = localStorage.getItem('currentUser')

      // No hay datos de usuario en localstorage o son invalidos
      // || userData.token === undefined || userData.token === null
      if (userData === null) {
        this.clearLocalStorage() // Reaction eliminará los datos invalidos en el localstorage
        return false
      }

      const user: IUser = JSON.parse(userData)

      if (user.token === undefined || user.token === null) {
        this.clearLocalStorage() // Reaction eliminará los datos invalidos en el localstorage
        return false
      }

      this.setCurrentUser(user)
      return true
    } catch (e) {
      this.clearLocalStorage()
      console.log(e)
    }
  }

  loadCompany() {
    try {
      // Abortar el proceso en caso de que el usuario no este cargado primero.
      if (!this.currentUser) return false

      let companyData = localStorage.getItem('currentCompany')
      // Sino hay compañia en localstorage o es invalida
      // cargar la primera del objeto de usuario.
      // companyData.KeyComany === undefined
      if (companyData === null) {
        return this.setDefaultCompanyAsCurrentCompany()
      }

      const company: ICompany = JSON.parse(companyData)
      if (company.KeyCompany === undefined) {
        return this.setDefaultCompanyAsCurrentCompany()
      }

      this.setCurrentCompany(company)
      return true
    } catch (e) {
      // cerrar sesión sino se pudo cargar una compañia
      this.clearLocalStorage()
      console.log(e)
    }
  }

  setDefaultCompanyAsCurrentCompany() {
    if (this.currentUser) {
      if (!this.currentUser.Companies || !this.currentUser.Companies.length) {
        throw new Error("No companies on current user localstorage data")
      }
      this.setCurrentCompany(this.currentUser.Companies[0])
      return true
    }
  }

  setCurrentUser(user: IUser) {
    this.currentUser = user;
  }

  setCurrentCompany(company: ICompany) {
    this.currentCompany = {
      ...company,
      PhoneMask: {
        min: 10,
        mask: "(000)000-0000"
      }
    }
  }

  forget() {
    this.currentUser = undefined
    this.currentCompany = undefined
  }

  clearLocalStorage() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentCompany');
  }

  get isUserLoggedInAndValid() {
    return (
      this.currentUser !== undefined &&
      this.currentUser.token !== undefined &&
      this.currentUser.token !== null
    )
  }

  updateTokens(bearer_token: string, refresh_token: string){
    if(!this.currentUser) throw new Error("No use data to update")
    this.currentUser = {...this.currentUser, token: bearer_token, refresh_token: refresh_token}
  }
}

decorate(UserStore, {
  currentUser: observable,
  currentCompany: observable,
  loadingUser: observable,
  setCurrentUser: action,
  forget: action,
  isUserLoggedInAndValid: computed,
  updateTokens: action,
})

export default new UserStore();
