import React, { useState, lazy, useEffect, useContext } from 'react';
import './App.scss';
import { Switch, Route,Redirect,useLocation, RouteComponentProps  } from "react-router-dom";
import { LOGIN, APP } from './Constants/Routes'
import Login from './Pages/Login'
import { inject, observer } from 'mobx-react';
import NotificationsContainer from 'Components/NotificationsContainer'
import ErrorBoundary from 'Components/ErrorBoundary'
import StoresContext from 'Contexts/StoresContext'
import useStores from 'hooks/useStores';
const AppInside = lazy(() => import('./Components/AppInside'))

const App = () => {
  let location = useLocation();
  const [previousLocation,setPreviousLocation] = useState<string|undefined>(undefined)
  const { axiosControlStore } = useStores()

  useEffect(() => {
    if(location.pathname !== previousLocation){
      axiosControlStore.cancelTokens()
    }
    setPreviousLocation(location.pathname)
  }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ErrorBoundary>

        <Switch>
          <Route exact path={LOGIN} component={Login} />
          <AppInside path={APP} />
          <Redirect to="/" />
        </Switch>
      
      <NotificationsContainer/>
    </ErrorBoundary>
  );
}



export default observer(App);
