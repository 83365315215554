import { decorate, observable, action } from 'mobx';

class CommonStore {

  appName = 'Vsap';
  remember = window.localStorage.getItem('rmb');
  appLoaded = false;

  setRemember(email) {
    this.remember = email
    window.localStorage.setItem('rmb',email);
  }

  setAppLoaded() {
    this.appLoaded = true;
  }

}

decorate(CommonStore, {
  appName: observable,
  remember: observable,
  appLoaded: observable,
  setRemember: action,
  setAppLoaded: action,
})

export default new CommonStore();
