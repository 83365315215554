// Transforma el código de idioma de i18next a el número correspondiente
// en el servicio
import Languages from 'Constants/Languages'

class LanguageParser {

    parseCurrentLanguege(){
        const i18nLanguege = localStorage.getItem('i18nextLng')
        if(i18nLanguege === null) return Languages.en.value

        // Obtener el valor de la norma iso639-1
        // elminando así el código regional (es-VE => es)
        const iso639_1Languege = i18nLanguege.length > 2 ? i18nLanguege.slice(0,2) : i18nLanguege

        switch (iso639_1Languege) {
            case Languages.en.iso639_1code:
                return Languages.en.value
            case Languages.es.iso639_1code:
                return Languages.es.value
            default:
                return Languages.en.value
        }
    }
}

export default new LanguageParser()