import {get, post, patch, del, responseBody,checkStatus} from './AgentSetup'

const RESOURCE = 'Patients'

const all = () => get(RESOURCE,{'$inlinecount': 'allpages','$orderby':'DateCreation'},false)
                    .then(response => responseBody(response,200))

const add = (data) => post(RESOURCE,data)

const update = (id, data) => patch(RESOURCE,`key=guid'${id}'`,data)
    .then(response=>checkStatus(response,204))

const delt = (id) => del(RESOURCE,id)
    .then(response=>checkStatus(response,204))

const single = id => 
    get(RESOURCE,{$filter: `IdPatient eq guid'${id}'`},true)
    .then(response=> responseBody(response,200))

const searchByDocumentId = documentId => 
    get(RESOURCE,{$filter: `DocumentoId eq '${documentId}'`},true)
    .then(response=>responseBody(response,200))

const suggestion = (documentId) => 
    get(RESOURCE,{$filter: `startswith(DocumentoId,'${documentId}')`},true)
    .then(response => responseBody(response,200))

const search = (term,top,skip) => 
    get(RESOURCE,{
        $filter: `(
            substringof('${term}',Name) or 
            substringof('${term}',Phone) or
            substringof('${term}',Address) or
            substringof('${term}',Zone) or
            substringof('${term}',DocumentoId))`,
        '$inlinecount': 'allpages',
        $skip: skip, 
        $top: top,
        $orderby:'DateCreation'
    },true)
    .then(response => responseBody(response,200))

export default {
    all,
    add,
    update,
    delt,
    single,
    suggestion,
    search,
    searchByDocumentId
}