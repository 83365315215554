import React from 'react'
import './index.scss'
import { inject, observer } from 'mobx-react'
import Notification from './Notification'
import useStores from 'hooks/useStores'

const NotificationsContainer = () => {

    const { uiStore } = useStores()

    function handleDissmission(index: number) {
        uiStore.dismissNotification(index)
    }

    return (
        <>
            {uiStore.notifications &&
                <div className="v-btnotify-container fixed-bottom text-white">
                    <ul className="p-0 mb-0">
                        {uiStore.notifications.map((notification, index) => {
                            return <Notification
                                key={index}
                                notification={notification}
                                index={index}
                                handleDissmission={handleDissmission} />
                        })}
                    </ul>
                </div>
            }
        </>
    )
}

export default observer(NotificationsContainer)