// ":id" indica que a la ruta se le debe concatenar ':id' en la propiedad
// patch del componente Route donde sea usado
// ejemplo <Route patch={PATIENT_EDIT}+':id'/>
// Esto mayormente se debe hacer en el AppInside.js

export const LOGIN = '/'
export const APP = '/app'
export const DASHBOARD = APP+'/dashboard'

//Prescripciones
export const PRESCRIPTIONS_LIST = APP+'/prescriptions'
export const PRESCRIPTION_ADD = APP+'/prescriptions/add'
export const PRESCRIPTION_EDIT = APP+'/prescriptions/edit/' //:id
export const PRESCRIPTION_DELETE = APP+'/prescriptions/delete/' //:id
export const PRESCRIPTION_DETAILS = APP+'/prescriptions/details/' //:id
export const PRESCRIPTIONS_MONITORING = APP+'/prescriptions/monitoring'
export const PRESCRIPTIONS_ASSING = APP+'/prescriptions/assing'

//Pacientes
export const PATIENTS_LIST = APP+'/patients'
export const PATIENT_ADD = APP+'/patients/add'
export const PATIENT_EDIT = APP+'/patients/edit/' //:id
export const PATIENT_DELETE = APP+'/patients/delete/' //:id
export const PATIENT_DETAILS = APP+'/patients/details/' //:id

//Auditorias
export const AUDITS_LIST = APP+'/audits/pending'
export const AUDITED_LIST = APP+'/audits/audited'

//Cierres de turno
export const CLOSURES_LIST = APP+'/closures'
export const CLOSURE_DETAILS = APP+'/closures/' //:id

export const DRIVERS_MAP = APP+'/drivers/map'