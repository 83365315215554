import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { DASHBOARD } from 'Constants/Routes'
import { withRouter } from 'react-router-dom'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidMount(){
        this.unlisten = this.props.history.listen((location,action)=>{
            if(this.state.hasError){
                this.setState({hasError: false})        
            }
        })
    }

    componentWillUnmount(){
        this.unlisten()
    }

    componentDidCatch(error, errorInfo) {
        this.setState({hasError: true})
    }
    reloadPage(){
        window.location.reload()
    }

    render() {
        const { t } = this.props

        if (this.state.hasError) {
            return <div className="text-center">
                <h1>{t('app_unexpected_error_occurred')}</h1>
                <div>
                    <span>{t('you_cant_try_to')} <Link onClick={this.reloadPage}>{t('reload_this_page')}</Link> </span><br/>
                    <span>{t('or')} <Link to={DASHBOARD}>{t('go_to_dashboard')}</Link></span>
                </div>
            </div>
        }
        return this.props.children;
    }
}
export default withTranslation()(withRouter(ErrorBoundary))